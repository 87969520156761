.LoaderBox {
  height: 20px;
  width: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

span.Loader,
div.Loader {
  height: 20px !important;
  width: 20px !important;
}
