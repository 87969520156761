.TableList {
  align-items: center;

  min-height: 44px;
  padding: 5px 20px;
  color: #141e2f;
  margin-bottom: 8px;

  border-radius: 8px;
}

.TableListHead {
  position: sticky;
  top: 0;

  margin-bottom: 10px;

  background: rgb(244, 244, 255);

  overflow: auto;
  z-index: 10;
}

.TableListRow {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 8%);
}

.TableListDivider {
  background: rgba(89, 106, 114, 0.1);
  border: 1px solid rgba(89, 106, 114, 0.1);
}

.TableListActive {
  background: rgba(255, 152, 41, 0.3);
  border: 1px solid rgba(255, 152, 41, 0.3);
}

.TableRowActive {
  background: rgba(255, 152, 41, 0.1);
  border: 1px solid rgba(255, 152, 41, 0.1);
}
