.NoDataScreen {
  height: calc(100vh - 520px);

  display: flex;
  justify-content: center;
  align-items: center;

  composes: PositionedCenter from "index.css";

  user-select: none;
  pointer-events: none;
}

.NoDataScreenSmall {
  margin-top: 100px;
  margin-bottom: 20px;
}

div.NoDataScreenCard {
  width: 747px;
  min-height: 370px;

  align-items: center;

  margin: auto;
  padding: 32px;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}

.NoDataScreenCardBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NoDataScreenImage {
  width: 45%;

  margin: auto;
  margin-bottom: 15px;
}

.NoDataScreen .NoDataScreenText {
  width: 60%;
  margin: auto;
  text-align: center;
}

h2.NoDataScreenHeading {
  padding-bottom: 7px;

  font-weight: 500;
}

@media screen and (max-width: 1050px) {
  .NoDataScreenSmall {
    margin-top: 80px;
  }
}

@media screen and (max-width: 909px) {
  .NoDataScreen {
    height: calc(100vh - 620px);
  }

  .NoDataScreenSmall {
    margin-top: 100px;
  }

  div.NoDataScreenCard {
    width: 550px;
  }

  .NoDataScreenText {
    width: 80%;
    margin: auto;
    text-align: center;
  }
}
