button.SubmitButton {
  min-height: 48px;

  margin-top: 15px;
  width: auto;
}

button.SubmitButton,
button.SubmitButton:hover {
  color: #ffffff;
  text-transform: none;
  border: 1px solid transparent;
}

button.Upload.LoadingSubmitButton {
  background-color: #f6f8fa;
  text-transform: none;
  height: 48px;
  margin-top: 15px;
}

button.SubmitButton div {
  color: #f6f8fa;
}

button.Large {
  padding: 11px 42px;
}

button.Upload,
button.Upload:hover {
  background-color: #0100be;
}

button.Delete,
button.Delete:hover {
  background-color: #eb5757;
}

button.SubmitButtonDisabled {
  border: 1px solid #0100be;
  background-color: white;
}

button.SubmitButton:disabled {
  opacity: 0.4;
  color: #ffffff;
}
