.DeleteModal {
  display: flex;
  justify-content: center;

  composes: PositionedCenter from "index.css";
}

.DeleteBox {
  width: 20%;
  min-width: 480px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 32px;
  margin: 0 auto;

  background: #ffffff;
  border-radius: 24px;

  font-size: 14px;
}

.FormHeader,
.FormFooter {
  width: 100%;

  display: flex;
  align-items: center;
}

.FormHeader {
  margin-bottom: 12px;
  justify-content: space-between;
}

.FormFooter {
  justify-content: flex-start;
  align-items: baseline;
}

.DeleteModal .DeleteBox .CloseButton {
  min-height: auto;

  align-self: center;

  margin: 0;
  padding: 0;
}

.DeleteModal .DeleteBox button {
  max-width: 86px;
  min-height: 48px;

  align-self: flex-start;

  padding: 10px 20px;
  margin: 0;

  font-size: 14px;
}

.DeleteModal .DeleteBox .CancelButton {
  padding: 12.5px 15px;
  margin-right: 16px;

  border: 1px solid #828282;
  border-radius: 8px;

  color: #828282;
  text-trans.deletebox: none;
}

.DeleteModal .DeleteBox .CancelButton:hover {
  background: transparent;
}

.DeleteModal .DeleteBox p {
  margin-bottom: 32px;
}
