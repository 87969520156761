.Modal {
  overflow: scroll;
}

.Group {
  composes: FlexJustifiedBetween from "index.css";

  margin-bottom: 21px !important;
}

.GroupTitle {
  width: 100%;
  margin-bottom: 21px !important;
}

.Loader {
  composes: PositionedCenter from "index.css";
}

/* FORM */

.CreateForm {
  width: 60%;
  min-width: 700px;

  position: static;
  transform: unset;

  margin: 20px auto;

  composes: FlexJustifiedBetween from "index.css";

  composes: PositionedCenter from "index.css";
}

.CreateForm form {
  width: 100%;
  height: 100%;

  background: #ffffff;
  border-radius: 24px;

  padding: 32px;

  font-size: 14px;
}

.CreateFormExtraLarge form {
  width: auto;
  margin: 0 auto 20px;
}

.FormHeader,
.FormFooter {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormHeader {
  margin-bottom: 20px;
}

.CreateForm form button {
  align-self: flex-start;

  margin: 10px 0;
}

.CreateForm form hr {
  margin: 20px 0;
}

.CreateForm form img {
  max-width: 130px;

  align-self: center;
}

.Loader {
  composes: PositionedCenter from "index.css";
}

.NameFnameAvatarContainer {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FullNameCollumn {
  flex-grow: 1;
  margin-right: 20px;
}

.UsersAvatarUploadWrapper {
  width: 170px;
  height: 170px;

  position: relative;

  cursor: pointer;
}

.UsersAvatarUpload {
  width: 100%;
  height: 100%;

  position: absolute;
}

.AvatarNotUploaded,
.AvatarUploaded {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 14px;
}

.AvatarNotUploaded {
  background: rgb(211, 211, 211);
}

.AvatarUploaded {
  position: relative;
}

.AvatarNotUploaded img {
  width: 50%;
  height: 50%;
}

.AvatarUploaded img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.DeleteAvatarButton {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;

  margin: 0 !important;

  z-index: 1000;
}

.UsersDetailsDataList {
  width: 100%;

  position: relative;

  padding: 24px;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}

.FormList {
  width: 100%;
  min-height: 100px;
}
