.PreviewForm {
  width: 350px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  position: absolute;
  top: calc(50% - 32px);
  bottom:calc(50% - 32px);
  left: calc(50% - 200px);
  right: calc(50% - 200px);

  padding: 10px 20px;

  background: #ffffff;
  border-radius: 24px;
}
