button.AddNewButton,
button.AddNewButton:hover {
  padding: 9px 16px;
  min-width: auto;
  background-color: #0100be;
  color: #ffffff;
  text-transform: none;

  white-space: nowrap;
}

button.AddNewButton:hover {
  background-color: rgba(1, 0, 190, 0.8);
}

button.AddNewButton:disabled {
  opacity: 0.4;
  color: #ffffff;
}
