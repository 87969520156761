.Navigation {
  display: flex;
}

li.NavigationItem,
li.NavigationItemBtn {
  border-radius: 24px !important;
}

li.NavigationItem {
  color: #000000;
  margin-right: 5px;
}

li.NavigationItemBtn {
  border: 2px solid #0100be;
}

li.NavigationItemBtn:hover {
  background-color: rgba(1, 0, 190, 1);
}

li.NavigationItemBtn:hover p {
  color: #ffffff;
}

div.HeroImage {
  min-height: 570px;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 100% 87%;
  padding-top: 100px;
  padding-bottom: 150px;
}

p.Heading {
  width: 65%;

  font-weight: 600;
  font-size: 48px;
  line-height: 130%;
  letter-spacing: -0.01em;
}

p.HeroText {
  width: 47%;
  margin-top: 40px;

  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
}

button.Button,
a.Button {
  min-width: 150px;
  width: fit-content;
  padding: 15px 50px;
  display: block;
  margin-top: 40px;
  background: #0100be;
  border-radius: 32px;
  font-size: 20px;
  font-weight: 600;
  text-transform: none;
  color: #ffffff;
}

button.Button:hover,
a.Button:hover {
  background: rgba(1, 0, 190, 0.8);
}

a.ButtonLight {
  background: #ffffff;
  color: #0100be;
}

a.ButtonLight:hover {
  background: rgba(245, 245, 245, 0.9);
}

.Moto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 90px 0;
  background: #0100be;
  text-align: center;
}

.Decor {
  background-size: 18%;
  background-repeat: no-repeat;
  background-position: 0 0;
}

p.BlockCategory {
  text-transform: uppercase;
  color: #0100be;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}

p.BlockHeading {
  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  margin: 24px 0;

  letter-spacing: -0.01em;
}

p.BlockText {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
}

h2.BlockLink {
  position: relative;
  display: block;
  width: fit-content;
}

h2.BlockLink::after {
  position: absolute;
  content: "";
  bottom: 5px;
  left: 0;
  width: 100%;
  height: 1px;

  background-color: #0100be;
}

.Note {
  background: #f4f4ff;
  border-radius: 20px;
  padding: 20px 40px;
  margin-top: 40px;
}

.NoteTag {
  text-align: center;
  background: rgba(1, 0, 190, 0.2);
  border-radius: 32px;
}

.NoteContent {
  padding: 5px;
}

.Footer {
  min-height: 300px;
  background-color: #141e2f;
}

.FooterBlock {
  padding: 80px 0;
}

.Pricing {
  min-height: 800px;
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg, #0100be 55%, #ffffff 55%);
}

.CardWrapper {
  display: flex;
  padding: 30px 70px;
  width: 80%;
  margin: 0 auto;
  height: 100%;
}

.Card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 30px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 40px 99px rgb(90 94 94 / 29%);
}

.CardTitle {
  text-transform: uppercase;
  padding-bottom: 15px;
  letter-spacing: 1.2;
}

p.CardSubtitle {
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 15px;
  font-size: 17px;

  color: #212525;
}

.CardFlexible {
  padding-top: 20px;
  flex-grow: 1;
}

.CardText {
  padding-top: 10px;
  color: #212525;
}

.CardBtn {
}

p.CardPrice {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
  font-size: 17px;
  color: #212525;
}

.PayInfo {
  height: 90vh;
}

.PayShadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  clip-path: inset(0px -30px 0px 0px);
}

p.PayPrice {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.03rem;
}

.SuccesBox {
  border-radius: 25ps;
  border: 1px solid #00a764;
}
