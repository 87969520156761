.Item {
  display: inherit;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  text-align: left;
}

.Divider {
  border-right: 1px solid #e0e0e0;
  padding: 10px 0;
}

.Group {
  height: 48px;
  padding: 15px;

  background: #f9f9f9;
  border: 1px solid #e2e5ed;
  box-sizing: border-box;
  border-radius: 12px;
}

.Tab {
  width: 100%;
  padding: 24px;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}

.MainForm {
  width: auto;
  min-width: auto;
  display: block;
  padding: 0;
  margin: 0;
  border-radius: 0;
  outline-offset: 5px;
  background-color: inherit;

  font-size: inherit;
}

.SoldInput {
  background-color: inherit;
  border: none;
  outline-offset: 5px;
}

.NoteTextareaBox {
  background-color: #f4f4ff;
  border-radius: 8px;
  width: fit-content;
  padding: 15px 32px;
  margin: 10px 0;
  width: 50%;
}

.NoteTextareaInput {
  background-color: inherit;
  border: none;
  color: #141e2f;
  font-family: sans-serif;
  font-size: 16px;
  outline-offset: 5px;
  width: 100%;
}

.NoteTextareaInput:focus-visible,
.NoteTextareaInput:active {
  outline: 1px solid rgba(1, 0, 190, 0.3);
}

.Fieldset {
  border-color: transparent;
}

.Modal {
  overflow-y: auto;
}

.CreateFormExtraLarge {
  position: static;
  transform: unset;
  margin: 15px auto;
  width: 950px;
  height: auto;

  padding: 32px;
  border-radius: 24px;
  background-color: #ffffff;

  font-size: 14px;
  margin: 70px auto 50px;
}

.CreateFormLarge {
  position: static;
  transform: unset;

  margin: 15px auto;
  width: 820px;
  height: auto;
}

.CreateFormLarge form {
  width: auto;
  margin: 100px auto 50px;
}

.FormHeader,
.FormFooter {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormHeader {
  margin-bottom: 20px;
}

.SearchField {
  width: 100%;
  padding: 0;
}

.SearchField > div {
  background-color: #f9f9f9;
  border: 1px solid #e2e5ed;
  border-radius: 12px;
}

.SearchFieldInput > div div {
  padding: 0 !important;
  top: calc(50% - 22px) !important;
}

.SearchField > div > div div {
  padding: 5px;
  border: 1px solid #e2e5ed;
  border-radius: 8px !important;
  background: #ffffff !important;
}

.SearchField > div > div div:nth-last-child(2) {
  padding: 0;
  border: transparent;
  border-radius: 0;
  background: transparent !important;
}

.SearchFieldInput > div > input {
  height: 36px;
  padding: 0 0 0 9px !important;
  border: none;
  min-width: 100px !important;
}

.SearchFieldInput > div > input:focus {
  outline: none;
}

.SearchFieldInput button {
  margin-top: 10px !important;
}

.DatePickerWide input {
  height: 56px;
}

.SubmitButton button {
  margin-top: 0;
  min-height: 40px;
  background-color: #ff9829;
}

.DatePickerInput:active,
.DatePickerInput:focus-visible {
  outline: 1px solid rgba(1, 0, 190, 0.3);
}

.DatePickerInput {
  width: 100%;
  height: 56px;
  padding: 11px 16px 11px;
  border: 1px solid #e2e5ed;
  background-color: #f9f9f9;
  border-radius: 12px;
  cursor: pointer;
}

.Modal {
  overflow-y: auto;
}

@media screen and (max-height: 850px) {
  .CreateFormExtraLarge {
    position: static;
    transform: unset;

    margin: 20px auto;
    width: 760px;
    height: auto;
  }

  .CreateFormExtraLarge form {
    width: auto;
    margin: 0 auto 20px;
  }
}
