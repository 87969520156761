p.PoDetailsStatus {
  margin-left: 12px;
  padding: 4px 10px 2px;

  border-radius: 8px;
  text-transform: uppercase;
}

.PoDetailsNotes {
  width: fit-content;
  padding: 10px 20px;
  margin: 20px 0;
  background: #f4f4ff;
  border-radius: 8px;
}

.PoDetailsDisapp {
  width: fit-content;
  padding: 10px 20px;
  margin: 20px 0;
  background: rgba(235, 87, 87, 0.1);
  border-radius: 8px;
}

.PoDetailsInfo {
  margin-top: 10px;
  padding: 20px 0;
  border-top: 1px solid #e0e0e0;
}

div.PoDetailsInfoItem {
  border-right: 1px solid #e0e0e0;

  padding: 20px 24px;
}

div.PoDetailsInfoItemFisrt {
  padding-left: 0;
}

div.PoDetailsInfoItemLast {
  border-right: none;
}

div.PoDetailsParts {
  margin-top: 40px;
}

.PoDetailPartRow {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
}

.PoDetailsFooterSummary {
  margin-top: 15px;
  padding: 20px 0;
  border-top: 1px solid #e0e0e0;
}

.PoModal {
  overflow-y: auto;
}

.PoForm {
  width: 820px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;

  font-size: 14px;
}

form.PoForm input {
  background-color: #ffffff;
}

.PoFormFooter {
  margin-top: 15px;
}

.PoButtonArrowIcon {
  margin-left: 5px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(13%) saturate(478%) hue-rotate(229deg) brightness(116%) contrast(100%);
}

.PoForm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 32px;

  margin: 15px auto;
  width: 820px;
  height: auto;
}

.PoForm .PoFormWrapper {
  width: auto;
  margin: 0 auto 20px;
}

.PoColumnHeader {
  background: #f4f4ff;
  width: 100%;
}

.PoColumnHeader div {
  color: #0100be;
}

.PoColumnHeaderCentered div[data-mui-internal-clone-element] {
  width: 100%;
  text-align: right;
}

.PoColumnHeaderRight {
  text-align: right;
}

.PoPartsForm {
  height: 50px;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 50px;
  background-color: #ffffff;
}

.PoPartsForm div {
  height: inherit;
}

form.PoLumberForm {
  width: 120px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;

  font-size: 14px;
}

div.PoLumberFormRadioList {
  margin-top: 20px;
  margin-bottom: 20px;
}

.PoModal {
  overflow-y: auto;
}

.PoLargeForm {
  composes: FlexJustifiedBetween from "index.css";

  padding: 32px;

  margin: 15px auto;
  width: 780px;
  height: auto;
}

.PoLargeForm .PoFormWrapper {
  width: auto;
  margin: 0 auto 20px;

  padding: 32px;
  background: #ffffff;
  border-radius: 24px;
}

.PoListSpacing {
  margin-bottom: 7px;
}

.NotesTruncated {
  white-space: nowrap;
  width: 100%; /* IE6 needs any width */
  overflow: hidden; /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis; /* Opera < 11*/
  text-overflow: ellipsis;
}
