.TabWidth {
  width: 100%;
}

.Tab {
  width: 100%;
  padding: 24px;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}

.FormUpload {
  height: 56px;
  width: 100%;

  padding: 16px;
  margin-bottom: 12px;

  background-color: #f9f9f9;
  border: 1px dashed #596a72;
  border-radius: 12px;
  cursor: pointer;
}

.FormUploadText {
  font-size: 16px;
  line-height: 24px;
  color: #9197a0;
}

.FormImagePreview {
  width: 29px;
  height: 29px;

  border-radius: 50%;
}

.FormAttachmentIcon {
  width: 12px;
  height: 20px;
}
