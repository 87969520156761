.Checkbox {
  margin-left: 15px;
  opacity: 0.7;
  width: 18px;
  height: 18px;
  align-self: center;
}

div.DetailsInfoItem {
  border-right: 1px solid #e0e0e0;

  padding: 20px 24px;
}

div.DetailsInfoItemFisrt {
  padding-left: 0;
}

div.DetailsInfoItemLast {
  border-right: none;
}

.SearchField {
  width: 100%;
  padding: 0;
}

.SearchField > div {
  background-color: #f9f9f9;
  border: 1px solid #e2e5ed;
  border-radius: 12px;
}

.SearchFieldInput > div div {
  padding: 0 !important;
  top: calc(50% - 22px) !important;
}

.SearchField > div > div div {
  padding: 5px;
  border: 1px solid #e2e5ed;
  border-radius: 8px !important;
  background: #ffffff !important;
}

.SearchField > div > div div:nth-last-child(2) {
  padding: 0;
  border: transparent;
  border-radius: 0;
  background: transparent !important;
}

.SearchFieldInput > div > input {
  height: 36px;
  padding: 0 0 0 9px !important;
  border: none;
  min-width: 100px !important;
}

.SearchFieldInput > div > input:focus {
  outline: none;
}

.SearchFieldInput button {
  margin-top: 10px !important;
}

.Block {
  composes: FlexJustifiedBetween from "index.css";
  width: 100%;

  padding: 32px;

  font-size: 14px;
}

.MockField,
.InputField {
  height: 56px;
  width: 100%;

  padding: 16px;
  margin-bottom: 12px;
  background-color: #f9f9f9;
  border: 1px solid #e2e5ed;
  border-radius: 12px;
  border-radius: 12px;
}

.InputFieldText {
  font-size: 16px;
  line-height: 24px;
  color: #141e2f;
}

.MockField {
  margin-top: 5px;
  padding: 12px 16px 16px;
  height: 45px;
}

button.ActionButton,
button.ActionButton:hover {
  padding: 9px 16px;

  background-color: #0100be;
  color: #ffffff;
  text-transform: none;
}

button.ActionButton:hover {
  background-color: #0100be;
  opacity: 0.9;
}

button.ActionButton:disabled {
  background-color: #0100be;
  opacity: 0.4;
  color: #ffffff;
}

.AccountMenuDropdown ul {
  padding-bottom: 0;
  background-color: #ffffff;
}

li.AccountMenuItem:hover {
  background-color: #f4f4ff;
}

.AddForm {
  width: 560px;
  composes: FlexJustifiedBetween from "index.css";

  composes: PositionedCenter from "index.css";

  background: #ffffff;
  border-radius: 24px;

  font-size: 14px;
}

.LargeForm {
  width: 740px;
  composes: FlexJustifiedBetween from "index.css";

  composes: PositionedCenter from "index.css";

  background: #ffffff;
  border-radius: 24px;

  font-size: 14px;
}

form.Form {
  width: 100%;

  padding: 32px;
  background: #ffffff;
  border-radius: 24px;
}

form.PartsForm {
  padding: 0;
  margin: 0;
  width: 100%;
}

button.InlineButton {
  font-size: 12px;
  color: #0100be;
  text-transform: unset;
  padding: 3px 7px;
  width: auto;
}

.Checkbox svg,
.Checkbox img {
  width: 25px;
  height: 25px;
}

.CollapseBtn {
  text-transform: none !important;
  color: black;
  border: 1px solid blue !important;
  font-size: 12px;
}
