.ChangeOrderCheckbox {
  margin-left: 15px;
  opacity: 0.7;
  width: 18px;
  height: 18px;
  align-self: center;
}

.ChangeOrderBox {
  background: #f9f9f9;
  border: 1px solid #e2e5ed;
  box-sizing: border-box;
  border-radius: 12px;
  min-width: 100px;
  padding: 8px 15px;
  margin-left: 10px;
  align-self: center;
  text-align: end;
}

.ChangeOrderDivider {
  border-right: 1px solid #e0e0e0;
  padding: 10px 0;
}

.ChangeOrderSelect ul {
  border-radius: 12px;
}

.ChangeOrderTextarea div {
  padding: 0;

  border-radius: 12px;
}

.ChangeOrderTextarea textarea {
  padding: 16px;
}

/* FORM */

.Modal {
  overflow-y: auto;
}

.CreateFormExtraLarge {
  position: static;
  transform: unset;

  margin: 15px auto;
  width: 760px;
  height: auto;

  margin: 70px auto 50px;

  padding: 32px;
  background: #ffffff;
  border-radius: 24px;
}

.CreateFormExtraLarge form {
  width: auto;
}

.CreateFormLarge {
  position: static;
  transform: unset;

  margin: 15px auto;
  width: 760px;
  height: auto;
}

.CreateFormLarge form {
  width: auto;
  margin: 100px auto 50px;
}

.FormHeader,
.FormFooter {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormHeader {
  margin-bottom: 20px;
}

.Group {
  width: 100%;
  composes: FlexJustifiedBetween from "index.css";

  margin-bottom: 21px !important;
}

.GroupHeader {
  width: 100%;

  display: flex;
  align-items: center;

  margin-bottom: 10px;
}

.GroupHeader p {
  margin: 0 !important;
}

.GroupHeader div {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}

.GroupHeader div button {
  width: 30px;
  height: 30px;

  margin: 0 0 0 10px !important;

  background: #ffffff;
  border: 1px solid #e2e5ed;
  border-radius: 8px;
}

.Group .GroupHeader div p {
  margin-left: 10px !important;
}

.GroupTitle {
  width: 100%;
  margin-bottom: 21px !important;
}

.CardLoader {
  width: 20px !important;
  height: 20px !important;

  position: absolute;
  left: 50%;
}

.Loader {
  composes: PositionedCenter from "index.css";
}

.PaymentHeader {
  width: 100%;

  display: flex;

  background: #f4f4ff;
  border-radius: 8px;
}

.HeaderItem {
  padding: 12px 16px;
}

.PaymentRow {
  width: 100%;

  display: flex;
  gap: 10px;
  align-items: center;

  padding: 10px 0;
  border-bottom: 1px solid #e5e7eb;
}

.PaymentRow.Alone {
  border-bottom: 1px solid transparent;
}

.PaymentItem {
  width: 100%;
  max-height: 44px;

  display: flex;
  justify-content: center;
}

.PaymentItem button {
  align-self: center !important;
  margin: 0 !important;
}

.PaymentItem div {
  width: 100%;
  max-height: 44px;
}

.PaymentItem div div input {
  max-height: 44px;
}

@media screen and (max-height: 850px) {
  .CreateFormExtraLarge {
    position: static;
    transform: unset;

    margin: 20px auto;
    width: 760px;
    height: auto;
  }

  .CreateFormExtraLarge form {
    width: auto;
    margin: 0 auto 20px;
  }
}
