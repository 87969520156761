.Modal {
  overflow: scroll;
}

.Group {
  width: 100%;
  composes: FlexJustifiedBetween from "index.css";

  margin-bottom: 21px !important;
}

.GroupTitle {
  width: 100%;
  margin-bottom: 21px !important;
}

.CardLoader {
  width: 20px !important;
  height: 20px !important;

  position: absolute;
  left: 50%;
}

.Loader {
  composes: PositionedCenter from "index.css";
}

.VendorNameLoader {
  display: flex;
}

/* FORM */

.CreateForm {
  width: 700px;
  composes: FlexJustifiedBetween from "index.css";

  composes: PositionedCenter from "index.css";
}

.CreateForm form {
  width: 100%;
  height: 100%;

  background: #ffffff;
  border-radius: 24px;
  padding: 32px;

  font-size: 14px;
}

.FormHeader,
.FormFooter {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormHeader {
  margin-bottom: 20px;
}

.CreateForm form button {
  align-self: flex-start;

  margin: 10px 0;
}

.CreateForm form hr {
  margin: 20px 0;
}

.CreateForm form img {
  max-width: 130px;

  align-self: center;
}

.Loader {
  composes: PositionedCenter from "index.css";
}
