.CheckboxIconSmall {
  width: 16px;
  height: 16px;
}

.CheckboxIconLarge {
  width: 25px;
  height: 25px;
}

.CheckboxIconDisabled {
  opacity: 0.5;
}
