.Fake {
  position: relative;
}

.Fake .Cover {
  width: 100%;
  height: 100%;
  
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  border-radius: 8px;
}

.Fake .Cover.Disabled {
  background-color: rgba(128, 128, 128, 0.3) !important;
}

.Fake .Loader {
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 13px);
}