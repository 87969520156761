ul .MenuItem {
  width: 100%;
  min-width: 120px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 3px 10px;
}

.MenuItem img {
  margin-left: 10px;
}

.Danger{
  color: #ff0000;
}
