.SignupPage {
  width: 100%;
  height: 93%;
  display: flex;
  justify-content: center;
  align-items: center;
}

hr.SignupPageDivider {
  margin: 20px 0;

  border-color: #e0e0e0;
}

.SignupPageHeading {
  padding-top: 20px;
  padding-bottom: 10px;

  font-weight: 600 !important;
  text-align: center;
}

.SignupPageText {
  width: 80%;
  margin: 0 auto !important;

  text-align: center;
  color: #596a72;
}

.SignupForm {
  display: flex;
  flex-direction: column;

  padding: 32px 48px;
  width: 476px;

  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);

  font-size: 14px;
}

.SignupForm input {
  font-size: 14px;
}

.SignupForm p {
  margin: 8px 0 0;
}

.SignupForm button {
  min-height: 44px;
  margin: 24px 0 10px;

  border-radius: 8px;
}

.SignupForm input {
  min-width: 380px;
  height: 40px;

  padding: 10px 16px;

  background: #f6f8fa;
  border: none;
  border-radius: 8px;
}

.SignupPage ::-webkit-input-placeholder,
.SignupPage :-ms-input-placeholder,
.SignupPage ::placeholder {
  font-size: 14px;
  line-height: 20px;

  color: #9197a0;
}

.SignupPage input:focus-visible {
  outline: none;
}

.SignupPage img {
  max-width: 130px;

  align-self: center;
}

.SignupFormAdornment {
  position: absolute;
  right: 10px;
  top: 21px;
}

button.SignupFormVisibilityIcon {
  height: 30px;
  min-height: 20px;
  margin: 0;
}

.LinkBox {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.LinkText {
  color: #0100be;
  padding-left: 5px;
}
