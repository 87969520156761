.Modal {
  overflow: scroll;
}

.VendorCard {
  position: relative;
}

.Group {
  width: 100%;
  composes: FlexJustifiedBetween from "index.css";

  margin-bottom: 21px !important;
}

.GroupHeader {
  width: 100%;

  display: flex;
  align-items: center;

  margin-bottom: 10px;
}

.GroupHeader p {
  margin: 0 !important;
}

.GroupHeader div {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}

.GroupHeader div button {
  width: 30px;
  height: 30px;

  margin: 0 0 0 10px !important;

  background: #ffffff;
  border: 1px solid #e2e5ed;
  border-radius: 8px;
}

.Group .GroupHeader div p {
  margin-left: 10px !important;
}

.GroupTitle {
  width: 100%;
  margin-bottom: 21px !important;
}

.CardLoader {
  width: 20px !important;
  height: 20px !important;

  position: absolute;
  left: 50%;
}

.Loader {
  composes: PositionedCenter from "index.css";
}

/* FORM */

.CreateForm {
  width: 85%;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  position: static;
  transform: unset;

  margin: 70px auto 50px;
  /* composes: PositionedCenter from "index.css"; */
}

.CreateForm form {
  width: 100%;
  height: 100%;

  background: #ffffff;
  border-radius: 24px;

  font-size: 14px;
  padding: 32px;
}


.FormHeader,
.FormFooter {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormHeader {
  margin-bottom: 20px;
}

.CreateForm form button {
  align-self: flex-start;

  margin: 10px 0;
}

.CreateForm form hr {
  margin: 20px 0;
}

.CreateForm form img {
  max-width: 130px;

  align-self: center;
}

.Loader {
  composes: PositionedCenter from "index.css";
}

.PaymentHeader {
  width: 100%;

  display: flex;

  margin-bottom: 16px;

  background: #f4f4ff;
  border-radius: 8px;
}

.HeaderItem {
  white-space: nowrap;
  overflow: hidden;

  padding: 12px 16px;
}

.HeaderItem p{
  text-overflow: ellipsis;
}

.PaymentRow {
  width: 100%;

  display: flex;
  gap: 10px;
  align-items: center;

  padding: 10px 0;
  border-bottom: 1px solid #e5e7eb;
}

.PaymentRow.Alone {
  border-bottom: 1px solid transparent;
}

.PaymentItem {
  width: 100%;
  max-height: 44px;

  display: flex;
  justify-content: center;
}

.PaymentItem button {
  align-self: center !important;
  margin: 0 !important;
}

.PaymentItem div {
  width: 100%;
  max-height: 44px;
}

.PaymentItem div div input {
  max-height: 44px;
}

.PartItem, 
.PaymentItem div {
  width: 100%;
  max-height: 44px;
}

.PartItem div div input {
  max-height: 44px;
}

.PartItem  p{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  padding: 0 12px;
}

.SearchField {
  border-color: red !important;
  padding: 0 !important;
}

.SearchField button {
  display: none !important;
}

.SearchField > div > div {
  padding: 0 !important;
}
