.Modal {
  overflow: scroll;
}

.Group {
  width: 100%;
  composes: FlexJustifiedBetween from "index.css";
}

.SubForm {
  width: 100%;

  padding: 24px;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}

.MasterFloorplanDetailsDataList {
  width: 100%;

  position: relative;

  padding: 24px;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}

.List {
  width: 100%;
}

.FormList {
  width: 100%;
  min-height: 100px;
}

.ListInput {
  width: 100%;
  max-height: 40px;

  margin-bottom: 0 !important;
  padding-right: 20px !important;
}

.ListInput div {
  max-height: 40px;
}

.ListInput div input {
  max-height: 40px;
}

.GroupTitle {
  width: 100%;
  margin: 14px 0 !important;
}

.Loader {
  composes: PositionedCenter from "index.css";
}

.UploadIcon {
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px;
  border-radius: 50%;

  transition: 0.3s;

  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}

.UploadIcon:hover {
  background: rgba(0, 0, 0, 0.062);
}

.CopyButton {
  color: #0100be !important;
}

.CopyButton:disabled {
  color: #596a72 !important;
}

/* FORM */
.FlorplansForm {
  width: 100%;
}

.CreateForm {
  width: 85%;
  height: auto;
  composes: FlexJustifiedBetween from "index.css";

  composes: PositionedCenter from "index.css";

  position: static;
  transform: unset;

  margin: 70px auto 50px;
  padding: 32px;

  background: #ffffff;
  border-radius: 24px;
}

.CreateFormExtraLarge {
  position: static;
  transform: unset;

  margin: 15px auto;
  width: 700px;
  height: auto;
}

.CreateForm form {
  width: 100%;

  padding: 0 !important;
}

.CreateFormExtraLarge form {
  width: auto;
  margin: 0 auto 20px;
}

.FormHeader,
.FormFooter {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormHeader {
  margin-bottom: 20px;
}

.CreateForm form button {
  align-self: flex-start;

  margin: 10px 0;
}

.CreateForm form hr {
  margin: 20px 0;
}

.CreateForm form img {
  max-width: 130px;

  align-self: center;
}

.Loader {
  composes: PositionedCenter from "index.css";
}

.PartsFormUpload {
  height: 56px;
  width: 100%;

  padding: 16px;
  margin-bottom: 12px;

  background-color: #f9f9f9;
  border: 1px dashed #596a72;
  border-radius: 12px;
  cursor: pointer;
}

.PartsFormUploadText {
  font-size: 16px;
  line-height: 24px;
  color: #9197a0;
}

.PartsFormImagePreview {
  width: 29px;
  height: 29px;

  border-radius: 50%;
}

.PartsFormAttachmentIcon {
  width: 12px;
  height: 20px;
}

.ButtonUpdate {
  padding: 10px 20px !important;

  text-transform: initial !important;
  background: #0100be !important;
  color: #ffffff !important;
}

.ButtonUpdate:hover {
  background: #0100be !important;
}

.LoadingButton {
  height: 48px !important;

  padding: 10px 10px 10px 40px !important;
  background: #f6f8fa !important;

  text-transform: capitalize !important;
}

.ButtonDelete {
  padding: 10px 20px !important;

  text-transform: initial !important;
  background: #eb5757 !important;
  border-color: #eb5757 !important;
  color: #ffffff !important;
}

.ButtonDelete:hover {
  background: #eb5757 !important;
}

.ButtonUpdate,
.LoadingButton,
.ButtonDelete {
  margin-left: 15px !important;
}

.ImageBox {
  cursor: pointer;
}

.ImageBoxContent {
  width: 200px;
  height: 200px;
}

.ImageBoxContentHidden {
  width: 200px;
  height: 200px;
}

.ImageBoxContent img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}
.Fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

@media screen and (max-height: 850px) {
  .CreateFormLarge {
    position: static;
    transform: unset;

    margin: 20px auto;
    width: 700px;
    height: auto;
  }

  .CreateFormLarge form {
    width: auto;
    margin: 0 auto 20px;
  }

  .ImageBoxContentHidden {
    display: none;
  }

  .ImageBox {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 1200px) {
  .ImageBoxContentHidden {
    display: none;
  }

  .ImageBox {
    margin-top: 20px !important;
  }
}
