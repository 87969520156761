.FilterSelect {
  width: 160px;
  height: 46px;
}

.FilterSelect div {
  margin: 0 !important;
  height: 46px !important;
  padding: 13px 16px !important;
}

.FilterSelect svg {
  top: calc(50% - 0.5em);
}

.SearchField {
  max-width: 220px;
}

.SearchField > div {
  margin-bottom: 0;

  background-color: #f9f9f9;
  border: 1px solid #e2e5ed;
  border-radius: 12px;

}

.SearchField > div > div {
  min-height: 44px !important;

  padding-top: 0 !important;
  padding-bottom: 0 !important;

}

.SearchFieldInput > div > input {
  height: 38px;
  padding: 0 0 0 9px !important;
  border: none;
}

.SearchFieldInput > div > input:focus {
  outline: none;
}
