.AppBar {
  border-bottom: 1px solid #f2f2f2;
}

.AppBarGrid {
  padding: 20px;
}

.AppBarIcon {
  border-radius: 50%;
  background-color: #f3f3fa !important;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
}

.AppBarLogo {
  order: 0;
}

.AppBarNavigation {
  order: 1;
}

.AppBarAccountMenu {
  order: 2;
}

@media screen and (max-width: 1105px) {
  .AppBarLogo {
    order: 0;
    padding-bottom: 20px;
  }

  .AppBarNavigation {
    order: 2;
    margin: 0 auto;
    width: 100%;
    padding-left: 0;
  }

  .AppBarAccountMenu {
    order: 1;
    padding-bottom: 20px;
  }
}
