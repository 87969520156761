div.MasterRolesModal {
  overflow: scroll;
}

div.MasterRolesModal form {
  width: 80%;
  margin: 20px auto;
}

.MasterRolesForm form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 32px;

  background: #ffffff;
  border-radius: 24px;

  font-size: 14px;
}

.MasterRolesForm form button {
  align-self: flex-start;

  margin: 10px 0;
}

.MasterRolesForm form hr {
  margin: 20px 0;
}

.MasterRolesForm form img {
  max-width: 130px;

  align-self: center;
}

div.MasterRolesFormPaper {
  margin-top: 25px;
  box-shadow: none;
}

.MasterRolesFormTable {
  min-width: 650px;
  margin-bottom: 30px;
}

.MasterRolesFormTable tr {
  padding: 10px;
}

.MasterRolesFormTableHead {
  background: #f2f2f2;
  border-radius: 0px;
}

.MasterRolesFormTable th,
.MasterRolesFormTable td {
  border-bottom: 1px solid #f2f2f2;
  line-height: 1;
}

.MasterRolesFormTableHeadRow th {
  padding: 10px 16px;

  background-color: #f2f2f2;
  color: #596a72;
}

.MasterRolesFormTableHeadItemCell {
  width: 101px;
}

.MasterRolesFormDescriptionCell {
  width: 199px;
  height: 44px;
}

.MasterRolesFormCheckboxCell {
  width: 149px;
  height: 44px;
  text-align: center;
}

.MasterRolesFormCheckboxCell svg,
.MasterRolesListCheckbox {
  width: 16px;
  height: 16px;
}

.MasterRolesListCheckbox {
  margin-left: 15px;
  opacity: 0.7;
  width: 18px;
  height: 18px;
}

.MasterRolesForm fieldset {
  border: none;
}

.MasterRolesSelect ul {
  border-radius: 12px;
}

@media screen and (max-width: 769px) {
  div.MasterRolesModal form {
    width: 92%;
    margin: 10px auto;
  }
}
