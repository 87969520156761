div.MenuDropdownPaper {
  margin-top: 14px;
  border-radius: 10px;
}

.MenuDropdownInput {
  border-radius: 26px;
}

.MenuDropdownInput input {
  width: 100%;
  height: 36px;
  padding-left: 40px;

  border-radius: 26px;
  background-color: #f4f4f7;
  background-position: 15px 52%;
  background-repeat: no-repeat;
}

.MenuDropdownInput fieldset {
  border: none;
}

.MenuDropdown ul {
  padding: 16px;
  width: 380px;
}

.MenuDropdownLinksList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.MenuDropdown li {
  width: 50%;
  padding: 0;
}

.MenuDropdown li:hover {
  background-color: #ffffff;
}

li.MenuDropdownItemActive {
  background-color: #f0f0ff !important;
  border-radius: 4px;
}

.MenuDropdown a {
  display: flex;

  width: 100%;

  padding: 6px 16px;
}

.MenuDropdownLinkDisabled {
  pointer-events: none;
  opacity: 0.5;
}

div.MenuDropdownItem {
  display: flex;
  justify-content: space-between;
}

.MenuDropdownIcon {
  width: 16px;
  height: 16px;
  margin-right: 10px;

  filter: brightness(0) saturate(100%) invert(51%) sepia(5%) saturate(9%) hue-rotate(335deg) brightness(98%) contrast(82%);
}

.MenuDropdownText {
  color: #808b91;
}

.MenuDropdown li:hover .MenuDropdownIcon,
.MenuDropdownItemActive .MenuDropdownIcon {
  filter: unset;
}

.MenuDropdown li:hover .MenuDropdownText,
.MenuDropdownItemActive .MenuDropdownText {
  color: #0100be;
}

.MenuDropdown fieldset {
  border: 0;
}
