button.ActionButton,
button.ActionButton:hover {
  padding: 9px 16px;

  color: #ffffff;
  text-transform: none;
}

button.ActionButton:hover {
  opacity: 0.9;
}

button.ActionButton:disabled {
  opacity: 0.4;
  color: #ffffff;
}
