.DatePickerPopper {
  z-index: 100;
}

.DatePickerCalendar {
  border-radius: 10px;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.DatePickerPopper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: transparent;
}

.DatePickerPopper[data-placement^="bottom"] .react-datepicker__triangle::before,
.DatePickerPopper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-bottom-color: #f4f4ff;
}

.DatePicker {
  width: 180px;
}

.DatePickerWide {
  width: 100%;
}

.DatePickerWide input {
  height: 56px;
}

.DatePickerInput {
  width: 100%;
  height: 45px;
  padding: 11px 16px 11px;
  border: 1px solid #e2e5ed;
  background-color: #f9f9f9;
  border-radius: 12px;
  cursor: pointer;
}

.DatePickerInput:active,
.DatePickerInput:focus-visible {
  outline: 1px solid rgba(1, 0, 190, 0.3);
}
