form.Form {
  width: 90%;
  min-width: 90%;
  background-color: inherit;
  padding: 0;
  margin: 0;
  border-radius: 0;
}

.Form input {
  outline: 1px solid rgba(1, 0, 190, 0.3);
  outline-offset: 2px;
  border: 1px solid #e2e5ed;
  border-radius: 4px;
  padding: 0 3px;
  background-color: #f9f9f9;
  height: 30px;
  width: inherit;
}
