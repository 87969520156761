.DevelopmentsListCheckbox {
  width: 16px;
  margin-left: 15px;
  opacity: 0.7;
  width: 18px;
  height: 18px;
}

.DevelopmentsForm {
  width: 100%;
}

.DevelopmentsDetails {
  width: 100%;

  padding: 24px;

  position: relative;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}

.DevelopmentsModal {
  overflow-y: auto;
}

.Group {
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.SubForm {
  width: 100%;

  padding: 24px;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}

.MasterFloorplanDetailsDataList {
  width: 100%;

  position: relative;

  padding: 24px;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}

.List {
  width: 100%;
}

.FormList {
  width: 100%;
  min-height: 100px;

  position: relative;
}

.ListInput {
  width: 100%;
  max-height: 40px;

  margin-bottom: 0 !important;
  padding-right: 20px !important;
}

.ListInput div {
  max-height: 40px;
}

.ListInput div input {
  max-height: 40px;
}

.GroupTitle {
  width: 100%;
  margin: 14px 0 !important;
}

.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.UploadIcon {
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px;
  border-radius: 50%;

  transition: 0.3s;

  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}

.UploadIcon:hover {
  background: rgba(0, 0, 0, 0.062);
}

.CardLoader {
  width: 20px !important;
  height: 20px !important;

  margin-right: 11px;
}

.PageHeaderContainer {
  display: flex;
}

.PageHeaderItem {
  flex-basis: 50%;
  flex: 1 0 auto;
}

.CopyButton {
  color: #0100be !important;
}

.CopyButton:disabled {
  color: #596a72 !important;
}

/* FORM */
.CreateForm {
  width: 85%;
  height: auto;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  position: static;
  transform: unset;

  padding: 32px;
  margin: 30px auto;

  background: #ffffff;
  border-radius: 24px;
}

.CreateFormExtraLarge {
  position: static;
  transform: unset;

  margin: 15px auto;
  width: 700px;
  height: auto;
}

.CreateForm form,
.DevelopmentsForm form {
  width: 100%;

  padding: 0 !important;
}

.CreateFormExtraLarge form {
  width: auto;
  margin: 0 auto 20px;
}

.FormHeader,
.FormFooter {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormHeader {
  margin-bottom: 20px;
}

.CreateForm form button {
  align-self: flex-start;

  margin: 10px 0;
}

.CreateForm form hr {
  margin: 20px 0;
}

.CreateForm form img {
  max-width: 130px;

  align-self: center;
}

.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PartsFormUpload {
  height: 56px;
  width: 100%;

  padding: 16px;
  margin-bottom: 12px;

  background-color: #f9f9f9;
  border: 1px dashed #596a72;
  border-radius: 12px;
  cursor: pointer;
}

.PartsFormUploadText {
  font-size: 16px;
  line-height: 24px;
  color: #9197a0;
}

.PartsFormImagePreview {
  width: 29px;
  height: 29px;

  border-radius: 50%;
}

.PartsFormAttachmentIcon {
  width: 12px;
  height: 20px;
}

.ButtonUpdate {
  padding: 10px 20px !important;

  text-transform: initial !important;
  background: #0100be !important;
  color: #ffffff !important;
}

.ButtonUpdate:hover {
  background: #0100be !important;
}

.LoadingButton {
  height: 48px !important;

  padding: 10px 10px 10px 40px !important;
  background: #f6f8fa !important;

  text-transform: capitalize !important;
}

.ButtonDelete {
  padding: 10px 20px !important;

  text-transform: initial !important;
  background: #eb5757 !important;
  border-color: #eb5757 !important;
  color: #ffffff !important;
}

.ButtonDelete:hover {
  background: #eb5757 !important;
}

.ButtonUpdate,
.LoadingButton,
.ButtonDelete {
  margin-left: 15px !important;
}

.ImageBox {
  cursor: pointer;
}

.ImageBoxContent {
  width: 200px;
  height: 200px;
}

.ImageBoxContentHidden {
  width: 200px;
  height: 200px;
}

.ImageBoxContent img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.Fake {
  position: relative;
}

.Fake .Cover {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  border-radius: 8px;
}

.Fake .Cover.Disabled {
  background-color: rgba(128, 128, 128, 0.3) !important;
}

.Fake .Loader {
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 13px);
}

/* ADD MEMBERS FORM */
.AddMemberForm {
  width: 100%;

  display: flex;
  justify-content: center;

  margin-bottom: 20px;
}

.SearchField {
  padding: 0;
  flex-grow: 1;
}

.SearchField > div {
  background-color: #f9f9f9;
  border: 1px solid #e2e5ed;
  border-radius: 12px;

  margin-bottom: 0 !important;
}

.SearchFieldInput > div div {
  padding: 0 !important;
  top: calc(50% - 12px) !important;
}

.SearchField > div > div div {
  padding: 5px;
  border: 1px solid #e2e5ed;
  border-radius: 8px !important;
  background: #ffffff !important;
}

.SearchField > div > div div:nth-last-child(2) {
  padding: 0;
  border: transparent;
  border-radius: 0;
  background: transparent !important;
}

.SearchFieldInput > div > input {
  height: 38px;
  padding: 0 0 0 9px !important;
  border: none;
  min-width: 100px !important;
}

.SearchFieldInput > div > input:focus {
  outline: none;
}

.Fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

@media screen and (max-height: 850px) {
  .CreateFormLarge {
    position: static;
    transform: unset;

    margin: 20px auto;
    width: 700px;
    height: auto;
  }

  .CreateFormLarge form {
    width: auto;
    margin: 0 auto 20px;
  }

  .ImageBoxContentHidden {
    display: none;
  }

  .ImageBox {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 1200px) {
  .ImageBoxContentHidden {
    display: none;
  }

  .ImageBox {
    margin-top: 20px !important;
  }
}
