.PageHeader {
  padding-bottom: 25px;

  user-select: none;
}

.PageHeader .ControlPanel {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: flex-end;
}

.PageHeader .ControlPanel > div,
.PageHeader .ControlPanel > button {
  margin-left: 12px;
}

.PageHeader .ControlPanel > div {
  padding: 0;
}

.PageHeaderText {
  display: flex;
  align-items: center;

  padding-bottom: 8px;
}

.PageHeaderIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  margin-right: 8px;

  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
}
