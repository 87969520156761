div.AccountMenu {
  padding: 0;
}

div.AccountMenuAvatar {
  width: 36px;
  height: 36px;
}

.AccountMenuName > p {
  font-size: 13px;
  line-height: 16px;
  color: #141e2f;
}

.AccountMenuRole > span {
  font-size: 10px;
  line-height: 12px;
}

.AccountMenuLogout ul {
  padding-bottom: 0;
}

li.AccountMenuItem {
  background-color: #f4f4ff;
  border-radius: 8px;
}
