.Modal {
  overflow: scroll;
}

.VendorCard {
  position: relative;
}

.VendorCard p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CardLoader {
  width: 20px !important;
  height: 20px !important;

  margin-right: 11px;
}

.Loader {
  composes: PositionedCenter from "index.css";
}

/* FORM */

.CreateForm {
  width: 65%;
  min-width: 700px;

  position: static;
  transform: unset;

  margin: 15px auto;
  composes: FlexJustifiedBetween from "index.css";

  composes: PositionedCenter from "index.css";

  padding: 32px;

  background: #ffffff;
  border-radius: 24px;
}

.FormHeader,
.FormFooter {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormHeader {
  margin-bottom: 20px;
}

.CreateForm form button {
  align-self: flex-start;

  margin: 10px 0;
}

.CreateForm form hr {
  margin: 20px 0;
}

.CreateForm form img {
  max-width: 130px;

  align-self: center;
}

.Group,
.PaymentGroup {
  width: 100%;
  composes: FlexJustifiedBetween from "index.css";

  position: relative;

  margin-bottom: 21px !important;
}

.GroupTitle {
  width: 100%;
  margin-bottom: 21px !important;
}

.PaymentGroup {
  border: 1px solid rgb(192, 192, 192);
  border-radius: 12px;

  padding: 20px 10px;
}

.Tabs {
  width: 100%;
}

.DiscountRow {
  width: 100%;
  height: 30px;

  display: flex;
  align-items: center;

  margin-bottom: 20px;

}

.DiscountField {
  margin: 0 !important;
}

.DiscountField input{
  height: 30px !important;
  width: 80px !important;

  border-radius: 3px !important;
}

.TaxRow {
  display: flex;
  align-items: center;
  flex: 1 0 auto;

  margin-top: 15px;
}

.TitleWithCheckbox {
  position: absolute;
  top: -12px;
  left: 20px;

  display: flex;
  align-items: center;

  padding: 0 5px;

  background: #ffffff;
}

.TitleWithoutCheckbox {
  position: absolute;
  top: -10px;
  left: 20px;
  padding: 0 5px;

  background: #ffffff;
}

.TitleWithCheckbox span {
  padding: 0;
}

.TitleWithCheckbox p{
  margin: 0;
}

.SearchField {
  width: 100%;
  height: auto;
  margin-bottom: 12px;
}

.SearchField div{
  height: auto;

  margin-bottom: 0;
}

.Loader {
  composes: PositionedCenter from "index.css";
}

@media screen and (max-height: 850px) {
  .CreateFormLarge {
    position: static;
    transform: unset;

    margin: 20px auto;
    width: 700px;
    height: auto;
  }

  .CreateFormLarge form {
    width: auto;
    margin: 0 auto 20px;
  }
}
