.PartsModal {
  overflow-y: auto;
}

.PartsFieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.PartsFieldset:disabled {
  opacity: 0.5;
}

.PartsAddForm {
  width: 820px;
  composes: FlexJustifiedBetween from "index.css";

  composes: PositionedCenter from "index.css";

  background: #ffffff;
  border-radius: 24px;

  font-size: 14px;
}

.PartsForm {
  composes: FlexJustifiedBetween from "index.css";

  padding: 32px;

  margin: 15px auto;
  width: 820px;
  height: auto;
}

.PartsForm .PartsFormWrapper {
  width: auto;
  margin: 0 auto 20px;
}

.PartsVendorPricingForm {
  width: 100%;
  padding: 0;
}

.PartsFormWrapper {
  background-color: white;

  border-radius: 24px;
}

.PartsCheckbox svg {
  width: 16px;
  height: 16px;
}

.PartsBlock {
  composes: FlexJustifiedBetween from "index.css";
  width: 100%;

  padding: 32px;

  font-size: 14px;
}

.PartsVendorPricingBlock {
  padding-top: 0;
}

.PartsFormHeader {
  margin-bottom: 20px;
}

.PartsFormHeader,
.PartsFormFooter {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PartsFormUpload {
  height: 56px;
  width: 100%;

  padding: 16px;
  margin-bottom: 12px;

  background-color: #f9f9f9;
  border: 1px dashed #596a72;
  border-radius: 12px;
  cursor: pointer;
}

.PartsFormUploadText {
  font-size: 16px;
  line-height: 24px;
  color: #9197a0;
}

.PartsFormImagePreview {
  width: 29px;
  height: 29px;

  border-radius: 50%;
}

.PartsFormImagePreviewWrapper {
  position: relative;
}

.PartsFormImagePreviewModal {
  width: 200px;
  height: auto;

  position: absolute;
  top : 30px; 
  left: 30px;
  z-index: 999;
}

.PartsFormImagePreviewModal img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  border: 2px solid #0100be;
  border-radius: 8px;
}

.PartsFormAttachmentIcon {
  width: 12px;
  height: 20px;
}

.PartsFormCheckbox svg {
  width: 21px;
  height: 21px;
}

label.PartsFormCheckbox {
  display: flex;
  align-items: center;
  width: fit-content;
}

.PartsConfirm {
  composes: PositionedCenter from "index.css";
  padding: 32px;
  min-width: 400px;

  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}

.PartsConfirmHeading {
  margin-right: 20px;
}

.PartsConfirmTop {
  margin-bottom: 10px;
}

button.PartsCancelButton {
  height: 48px;
  padding: 5px 15px;
  margin-top: 15px;
  margin-right: 20px;

  border: 1px solid #828282;
  border-radius: 8px;

  color: #828282;
  text-transform: none;
}

button.PartsCancelButton:hover {
  background: #ffffff;
}

.PartsCheckboxes {
  height: 16px;
  opacity: 0.7;
}

.PartsVendorPricingBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PartsVendorPricingList {
  padding: 5px 20px;
  border-bottom: 1px solid #e5e7eb;
  align-items: center;
}

.PartsVendorPricingSelect {
  width: 100%;
}

.PartsVendorPricingSelect div {
  width: inherit;
  height: 35px !important;
  padding: 7px 16px !important;

  margin-bottom: 0 !important;
  border-radius: 0 !important;
}

@media screen and (max-height: 850px) {
  .PartsForm {
    margin: 20px auto;
    width: 800px;
    height: auto;
  }

  .PartsForm .PartsFormWrapper {
    width: auto;
    margin: 0 auto 20px;
  }
}
