html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
  color: #9197a0 !important;
  opacity: 1;
}

body {
  min-width: 768px;
  background-color: #fbfbfb;
}

a {
  text-decoration: none;
  color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.LoaderScroll {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 20px 0;
}

.LoaderScroll.center {
  position: absolute;
  z-index: 1000;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}

.GoHomeButton,
.GoHomeButton:hover {
  padding: 9px 16px;
  margin-top: 15px;
  min-width: 170px;
  background-color: #0100be;
  color: #ffffff;
  text-transform: none;

  white-space: nowrap;
  border-radius: 8px;
}

.MuiAlert-root {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10000;

  background: #ffd1d1;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}

.MuiAutocomplete-loading {
  display: none;
}

.PositionedCenter,
.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.FlexJustifiedBetween {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Form {
  width: 700px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #ffffff;
  border-radius: 24px;

  font-size: 14px;
}

.Form form {
  width: 100%;

  padding: 32px;
}

.FormHeader {
  margin-bottom: 20px;
}

.Form form button {
  align-self: flex-start;
}

.FormHeader,
.FormFooter {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Textarea div {
  padding: 0;

  border-radius: 12px;
}

.Textarea textarea {
  padding: 16px;
}

.Fake {
  position: relative;
}

.Fake .Cover {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  border-radius: 8px;
}

.Fake .Cover.Disabled {
  background-color: rgba(128, 128, 128, 0.3) !important;
}

.Fake .Loader {
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 13px);
}

/* REACT TAG INPUT LIBRARY OVERRIDE */

.react-tag-input {
  min-height: 56px !important;
  color: #141e2f !important;
  font-family: inherit;

  margin-bottom: 10px;
  padding: 0 15px;

  background: #f9f9f9 !important;

  border: 1px solid #e2e5ed;
  border-radius: 12px !important;
}

.react-tag-input__input {
  flex-grow: 1;
  width: auto;
  margin: 5px 0;

  font-size: 14px !important;
}

.react-tag-input__tag {
  padding: 5px;
  border: 1px solid #e2e5ed;
  border-radius: 8px !important;
  background: #ffffff !important;
}

.react-tag-input__tag__remove {
  background: #ffffff !important;
  border-radius: 8px !important;
}

.react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  background: #141e2f !important;
}

.react-tag-input__tag__content {
  font-family: sans-serif;
}

.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input:-webkit-input-placeholder {
  color: #9197a0 !important;
}

.react-tag-input__input::-webkit-input-placeholder {
  color: #9197a0;
}

/* REACT DATEPICKER  LIBRARY OVERRIDE */

.react-datepicker__header {
  background: #f4f4ff;
  border: none;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--selected {
  border-radius: 50%;
  background: rgba(1, 0, 190, 1);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)
  .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected {
  border-radius: 50%;
  background: rgba(1, 0, 190, 1);
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border-radius: 50%;
  background: rgba(1, 0, 190, 0.4);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: rgba(1, 0, 190, 1);
}
