.InfoCard {
  max-width: unset !important;

  display: flex;
  flex-direction: column;

  position: relative;

  border-radius: 24px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08) !important;

  cursor: pointer;
}

.InfoCard .Cover {
  width: 100%;
  height: 100%;
  
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.CardAvatar {
  width: 100%;
  height: 100%;
}

.InfoCard .Cover.Disabled {
  background-color: rgba(128, 128, 128, 0.3) !important;
}

.InfoCard .CheckboxField label span:first-child{
  padding: 0 9px;
}

.InfoCard hr {
  margin: 0 16px;
}

.InfoCard .CardActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.InfoCard .CardActions .SwitchSection{
  display: flex;
  align-items: center;
}

.InfoCard .CardActions .SwitchLabel {
  margin: 0 10px;
}

.InfoCard .InfoCardContent {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: space-between;
  
  position: relative;
  
  padding: 24px 18px 28px;
}

.Loader {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

/*  ASSEMBLIES SPECIAL */
.InfoCard.AssembliesCard {
  min-height: 261px;
}

/*  COMPANIES SPECIAL */
.InfoCard.CompaniesCard {
  min-height: 444px;
}

/* USER SPECIAL */
.InfoCard.UserCard {
  min-height: 312px;
}