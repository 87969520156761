.SearchField {
  width: 100% !important;
  padding: 0;
}

.SearchField > div {
  background-color: #f9f9f9;
  border: 1px solid #e2e5ed;
  border-radius: 12px;
}

.SearchField > div > div div {
  padding: 5px;
  border: 1px solid #e2e5ed;
  border-radius: 8px !important;
  background: #ffffff !important;
}

.SearchField > div > div div:nth-last-child(2) {
  padding: 0;
  border: transparent;
  border-radius: 0;
  background: transparent !important;
}

.SearchFieldInput > div > input {
  height: 38px;
  padding: 0 0 0 9px !important;
  border: none;
  min-width: 100px !important;
}

.SearchFieldInput > div > input:focus {
  outline: none;
}
