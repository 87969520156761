.Search {
  width: 100%;
  max-width: 240px;

  position: relative;

  padding: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.Search div {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.Search div div {
  border: none;
}

.Search input {
  max-height: 44px;

  padding: 0;

  border: none;
}

.Search input:focus {
  outline: none;
}
