.LoginPage {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

hr.LoginPageDivider {
  margin: 20px 0;

  border-color: #e0e0e0;
}

.LoginPageHeading {
  padding-top: 20px;
  padding-bottom: 10px;

  font-weight: 600 !important;
  text-align: center;
}

.LoginPageText {
  width: 80%;
  margin: 0 auto !important;

  text-align: center;
  color: #596a72;
}

.LoginForm {
  display: flex;
  flex-direction: column;

  padding: 32px 48px;
  width: 476px;

  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);

  font-size: 14px;
}

.LoginForm input {
  font-size: 14px;
}

.LoginForm p {
  margin: 8px 0 0;
}

.LoginForm button {
  min-height: 44px;
  margin: 24px 0 10px;

  border-radius: 8px;
}

.LoginForm input {
  min-width: 380px;
  height: 40px;

  padding: 10px 16px;

  background: #f6f8fa;
  border: none;
  border-radius: 8px;
}

.LoginPage ::-webkit-input-placeholder,
.LoginPage :-ms-input-placeholder,
.LoginPage ::placeholder {
  font-size: 14px;
  line-height: 20px;

  color: #9197a0;
}

.LoginPage input:focus-visible {
  outline: none;
}

.LoginPage img {
  max-width: 130px;

  align-self: center;
}

.LoginFormAdornment {
  position: absolute;
  right: 10px;
  top: 21px;
}

button.LoginFormVisibilityIcon {
  height: 30px;
  min-height: 20px;
  margin: 0;
}
