ul.Navigation {
  background-color: #f9f9f9;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

div.NavigationItem {
  border-radius: 24px;
  max-width: 168px;
  width: 148px;
  height: 40px;
  justify-content: center;
}

div.NavigationItemDefault:hover {
  color: #828282;
  border-radius: 24px;
}

div.NavigationItemActive {
  background-color: #0100be;
  color: #ffffff;
}

div.NavigationItemActive:hover {
  background-color: #0100be;
}

.NavigationItemActive > *,
.NavigationItemActive img {
  filter: brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(0%) hue-rotate(299deg) brightness(103%) contrast(101%);
}

.Navigation .NavigationListIcon {
  min-width: 16px;
  padding-right: 10px;
}

.NavigationIcon {
  filter: brightness(0) saturate(100%) invert(51%) sepia(5%) saturate(9%) hue-rotate(335deg) brightness(98%) contrast(82%);
}

.NavigationText {
  padding-right: 10px;
}
